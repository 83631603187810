.th-signup {
  background: url("https://images.pexels.com/photos/2325446/pexels-photo-2325446.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.form-control:focus,
.ant-select-focused:not(.ant-select-disabled),
.ant-select:not(.ant-select-customize-input),
.ant-select-selector {
  box-shadow: none !important;
}

.navigation .nav-item > button,
.navigation .nav-item {
  outline: none !important;
}

/* collaborations */
.th-cell-form-image-box {
  padding: 10px 8px;
  border: 1px solid rgba(51, 42, 124, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 1rem;
}

.th-cell-img-box {
  width: 10vw;
  height: 6vw;
}

.th-collab-logo-box {
  width: 8vw;
  height: 6vw;
}

.th-team-img-box {
  width: 12.5vw;
  height: 12.5vw;
}

/* Event gallery */

.th-event-image {
  height: 8vw;
  width: 12vw;
}

.th-init-image-box {
  width: 12vw;
  height: 12vw;
}

.th-gallery-img {
  width: 100%;
  cursor: pointer;
  height: 12vw;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.th-gallery-container {
  position: relative;
}

.th-gallery-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.th-gallery-img-wraper {
  background: #000000;
}

.th-gallery-img-wraper:hover .th-gallery-img {
  opacity: 0.4;
}

.th-gallery-img-wraper:hover .th-gallery-middle {
  opacity: 1;
}

.th-gallery-middle-text {
  color: #fff;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
}

.th-gallery-modal-img-card {
  height: 12vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 760px) {
  .th-gallery-img-box,
  .th-gallery-modal-img-card {
    height: 50vw;
  }
}

.th-gal-event {
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 0;
  color: #521e86;
}

.ant-modal-close-x {
  display: none;
}

.th-link {
  text-decoration: none !important;
}

.th-team-gallery-btn,
.th-team-gallery-btn:hover {
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin: 0;
  background: #0088ff;
  font-size: 20px;
  font-weight: 600;
}
